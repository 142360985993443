import React, { useEffect, useState } from 'react';
import * as API from '../../API';
import { international, Country } from '../../Helpers';
import { Location } from '../../interfaces';

export default function LocationAware() {
  const [hasInternational, setHasInternational] = useState<boolean>(false);
  const [country, setCountry] = useState<Country>({ name: '', cta: '', text: '', url: ''});

  const loadData = async (coords: Location) => {
    try {
      const userCountryCode = await API.locate(coords);
      if (userCountryCode && international[userCountryCode]) {
        setHasInternational(true);
        setCountry(international[userCountryCode]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        function(position) {
          loadData({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        function(error) {
          console.log(`Geolocation error ${error.code} (${error.message})`);
        }
      );
    } else {
      loadData({});
    }
  }, []);

  return (
    <>
      {hasInternational &&
        <div className="uq-section uq-section--centered pt-0">
          <div className="uq-section__container">
            <div className="uq-section__content">
              <h2>Donating from {country.name}</h2>
              <p>{country.text}</p>
              <p>{country.cta}</p>
              <a href={country.url} className="uq-button">YES - Donate here</a>
            </div>
          </div>
        </div>
      }
    </>
  );
}