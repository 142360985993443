import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Donation } from '../../classes/Donation';
import { Metrics } from '../../classes/Metrics';

export default function Success(
  { donation, metrics }:
  {
    donation: Donation,
    metrics: Metrics,
  }
) {
  useEffect(() => {
    // Do a reset first
    metrics.pushData({ ecommerce: null });
    // Push the payment data
    metrics.pushData({
      ecommerce: {
        purchase: {
          actionField: {
            id: donation.invoiceNumber,
            revenue: donation.amount,
          },
          products: [{
            name: donation.fundId,
            price: donation.amount,
          }],
        },
      },
    });
  }, [donation, metrics]);

  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    address: {
      '@type': 'PostalAddress',
      name: 'Advancement and Community Engagement',
      addressCountry: 'AU',
      addressLocality: 'St Lucia',
      addressRegion: 'QLD',
      postalCode: '4072',
      streetAddress: 'The University of Queensland',
    },
    email: 'donations@uq.edu.au',
    name: 'Advancement and Community Engagement',
    telephone: '+61 7 3346 3909',
  };

  return (
    <>
      <div className="uq-grid uq-grid--golden donation-form">
        <div className="uq-grid__col">
          <h1>Thank you for your support</h1>

          <ul className="list-confirmation">
            <li><strong>Receipt Number: </strong>{donation.invoiceNumber}
              <span className="summary-note">
                Please quote this number if you need to contact us regarding this donation.<br/>
                You will receive an email receipt for your donation shortly.
              </span>
            </li>
            <li><strong>Payment date: </strong>{new Date(donation.responseAt).toLocaleDateString()}</li>
            <li><strong>Total value: </strong>${donation.amount}.00</li>
          </ul>
        </div>

        <div className="uq-grid__col">
          <h3>Contact Us</h3>
          <p>
            If you would like to discuss your gift, please contact Advancement and Community Engagement.<br/>
            <br/>
            <b>Phone:</b><br/>
            <a href={`tel:${schema.telephone.replaceAll(' ', '')}`}>{ schema.telephone }</a><br/>
            Mon-Fri 9am-5pm (AEST)<br/>
            <br/>
            <b>Email: </b><br/>
            <a href={`mailto:${schema.email}`}>{ schema.email }</a><br/>
            <br/>
            <b>Mail:</b><br/>
            { schema.address.name }<br/>
            { schema.address.streetAddress }<br/>
            { schema.address.addressLocality }, { schema.address.addressRegion } { schema.address.postalCode }
          </p>
          <script type='application/ld+json' dangerouslySetInnerHTML={{
            __html: JSON.stringify(schema)
          }} />
        </div>
      </div>

      <div className="uq-section uq-section--centered uq-section--shaded">
        <div className="uq-section__container">
          <div className="uq-section__content">
            <h2>I would like to</h2>
          </div>
        </div>

        <div className="uq-grid uq-grid--thirds">
          <div className="uq-grid__col">

            <div className="uq-card">
              <div className="uq-card__content-wrapper">
                <div className="uq-card__content">
                  <div className="uq-card__header">
                    <h3 className="uq-card__title">Make a new donation</h3>
                  </div>
                  <div className="uq-card__body">
                    <p>All donations, large and small, are gratefully received</p>
                  </div>
                </div>
                <div className="uq-card__actions">
                  <Link
                    className="uq-button uq-button--purple"
                    to={ donation.appealId ? `/?Appeal_ID=${donation.appealId}` : '/' }
                    onClick={() => {
                      metrics.pushData({
                        event: 'Custom click',
                        click_category: 'UQ giving donation app',
                        click_action: 'Step click',
                        click_label: 'New donation',
                        click_url: `${window.location.origin}/`
                      });
                    }}
                  >
                    Donate
                  </Link>
                </div>
              </div>
            </div>

          </div>
          <div className="uq-grid__col">
            <div className="uq-card">
              <div className="uq-card__content-wrapper">
                <div className="uq-card__content">
                  <div className="uq-card__header">
                    <h3 className="uq-card__title">Contact us</h3>
                  </div>
                  <div className="uq-card__body">
                    <p>If you would like to discuss your gift, please contact Advancement and Community Engagement.</p>
                  </div>
                </div>
                <div className="uq-card__actions">
                  <a className="uq-button uq-button--purple" href="https://alumni.uq.edu.au/contact-us" onClick={() => {
                    metrics.pushData({
                      event: 'Custom click',
                      click_category: 'UQ giving donation app',
                      click_action: 'Advancement and Community Engagement',
                      click_label: 'Advancement and Community Engagement',
                      click_url: 'https://alumni.uq.edu.au/contact-us'
                    });
                  }}
                  >Contact Advancement and Community Engagement</a>
                </div>
              </div>
            </div>
          </div>
          <div className="uq-grid__col">
            <div className="uq-card">
              <div className="uq-card__content-wrapper">
                <div className="uq-card__content">
                  <div className="uq-card__header">
                    <h3 className="uq-card__title">Visit the UQ website</h3>
                  </div>
                  <div className="uq-card__body">
                    <p>Learn more about The University of Queensland. Take a look at who we are and what we do.</p>
                  </div>
                </div>
                <div className="uq-card__actions">
                  <a className="uq-button uq-button--purple" href="https://uq.edu.au" onClick={() => {
                    metrics.pushData({
                      event: 'Custom click',
                      click_category: 'UQ giving donation app',
                      click_action: 'Visit UQ Website',
                      click_label: 'Visit UQ Website',
                      click_url: 'https://uq.edu.au'
                    });
                  }}
                  >Visit UQ Website</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
