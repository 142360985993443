import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { emailValidation, getConfig, phoneValidation } from '../../API';
import { Donation, periodToString, PRESET_RELATIONS, Relationship, relationToString } from '../../classes/Donation';
import { Fund } from '../../classes/Fund';
import { Metrics } from '../../classes/Metrics';
import { countries, CountryCodes, inputError, inputSuccess } from '../../Helpers';
import { ExperianEmailResult, ExperianPhoneResult } from '../../interfaces';
import Alert, { AlertLevel } from "../Alert/Alert";

// https://docs.experianaperture.io/email-validation/email-validate-api/api-reference/validation-responses/
const experianEmailAccepted = [
  'verified',
  'timeout',
  'acceptAll',
  'roleAccount',
  'unknown'
];

// TODO - Remove this or implement better user error messages
/*
const experianEmailInvalid = [
  'undeliverable',
  'mailboxDisabled',
  'mailboxDoesNotExist',
  'mailboxFull',
  'syntaxFailure',
  'unreachable',
  'illegitimate',
  'typoDomain',
  'localPartSpamTrap',
  'Profanity',
  'disposable',
  'relayDenied',
];
*/

// https://docs.experianaperture.io/phone-validation/experian-phone-validation/api-reference/confidence-level/
const experianPhoneAccepted = [
  'Verified',
  'Absent',
  'Unknown',
  'No coverage',
];

// TODO - Remove this or implement better user error messages
/*
const experianPhoneInvalid = [
  'Teleservice not provisioned',
  'Unverified',
  'Dead',
];
*/

const errorClass = 'uq-error-message';
const successClass = 'uq-input--large uq-input--success';

export default function PersonalDetails(
  { fund, donation,  setDonation, metrics }:
    {
      fund: Fund,
      donation: Donation,
      setDonation: React.Dispatch<React.SetStateAction<Donation>>,
      metrics: Metrics
    }
) {
  // internal state management for state data
  const [showOrganisationFields, setShowOrganisationFields] = useState<boolean>(Boolean(donation.companyName));
  const [organisationName, setOrganisationName] = useState<string>(donation.companyName);
  const [organisationABN, setOrganisationABN] = useState<string>(donation.companyABN);
  const [organisationPositionTitle, setOrganisationPositionTitle] = useState<string>(donation.companyPosition);
  const [title, setTitle] = useState<string>(donation.title || '');
  const [givenName, setGivenName] = useState<string>(donation.firstName);
  const [surname, setSurname] = useState<string>(donation.lastName);
  const [email, setEmail] = useState<string>(donation.email);
  const [phonePrefix, setPhonePrefix] = useState<string>(donation.phonePrefix.length ? donation.phonePrefix : '+61');
  const [phoneNumber, setPhoneNumber] = useState<string>(donation.phone);
  const [emailValidated, setEmailValidated] = useState<boolean>(donation.emailValidated);
  const [phoneNumberValidated, setPhoneNumberValidated] = useState<boolean>(donation.phoneValidated);
  const [titleOptions, setTitleOptions] = useState({});
  const [relationship, setRelationship] = useState<string>(donation.relationship || '');
  const [showOtherRelation, setShowOtherRelation] = useState<boolean>(false);
  const [otherRelation, setOtherRelation] = useState<string>('');
  const history = useHistory();

  let { fundUrl, donationAmount } = useParams<{ fundUrl: string; donationAmount: string }>();
  const linkData = {
    event: 'Custom click',
    click_category: 'UQ giving donation app',
    click_label: 'N/A',
    click_url: undefined,
  };

  // internal state management fields for UI
  const [emailMessage, setEmailMessage] = useState<string>('');
  const [titleMessage, setTitleMessage] = useState<string>('');
  const [givenNameMessage, setGivenNameMessage] = useState<string>('');
  const [organisationNameMessage, setOrganisationNameMessage] = useState<string>('');
  const [phoneMessage, setPhoneMessage] = useState<string>('');
  const [surnameMessage, setSurnameMessage] = useState<string>('');
  const [relationMessage, setRelationMessage] = useState<string>('');
  const [otherRelationMessage, setOtherRelationMessage] = useState<string>('');
  const [emailSearching, setEmailSearching] = useState<boolean>(false);
  const [phoneSearching, setPhoneSearching] = useState<boolean>(false);

  const onShowOrganisationChange = () => {
    setShowOrganisationFields(!showOrganisationFields);
    // Clear existing data
    setOrganisationName('');
    setOrganisationABN('');
    setOrganisationPositionTitle('');
  };

  useEffect(() => {
    // Redirect if information is missing
    if (fund.id === '') {
      history.push('/');
    } else if (donation.amount < 5) {
      history.push(`/${fund.id}`);
    }
    // @ts-ignore
    document.getElementById('content').scrollIntoView({
      behavior: 'smooth'
    });

    const getTitles = async () => {
      const response = await getConfig('titles');
      if (response.ok) {
        const jsonData = await response.json();
        setTitleOptions(JSON.parse(jsonData));
      }
    }

    // If the relationship is "Other" lets update what is displayed
    if (donation?.relationship?.length && ! (PRESET_RELATIONS).includes(donation.relationship)) {
      setShowOtherRelation(true);
      setRelationship(Relationship.OTHER);
      setOtherRelation(donation.relationship);
    }

    getTitles();
  }, [fund, donation, history]);

  const onEmailChanged = async (event?: React.FocusEvent<HTMLInputElement>, id = '') => {
    setEmailValidated(false);
    // some basic checks
    const isEmailRoughlyOkay = email.includes('@') && email.includes('.') && email.length > 4;

    if (isEmailRoughlyOkay) {
      setEmailSearching(true);

      try {
        const response = await emailValidation(email);
        if (! response.ok) {
          // Throw to be caught straight after to prevent logic duplication of the error handling
          throw new Error(response.statusText);
        }
        const data = await response.json();
        const result: ExperianEmailResult = data.response.result;
        if (experianEmailAccepted.indexOf(result.verbose) !== -1) {
          setEmailValidated(true);
          setEmailMessage('');
          inputSuccess(event, id);
        } else {
          setEmailValidated(false);
          setEmailMessage('Invalid email address');
          inputError(event, id);
        }
        setEmailSearching(false);
      } catch (error) {
        console.log('Error', error);
        setEmailMessage('Unable to validate email address');
        setEmailValidated(false);
        setEmailSearching(false);
      }
    } else {
      setEmailMessage('Please enter an email address');
      setEmailValidated(false);
      setEmailSearching(false);
    }
  }

  const onPhoneChanged = async (event?: React.FocusEvent<HTMLInputElement>, id = '') => {
    setPhoneNumberValidated(false);
    if (phoneNumber.length > 4) {
      setPhoneSearching(true);
      // join prefix and phone number
      let fullPhoneNumber = phonePrefix + ' ' + phoneNumber;

      try {
        const response = await phoneValidation(fullPhoneNumber);
        if (! response.ok) {
          // Throw to be caught straight after to prevent logic duplication of the error handling
          throw new Error(response.statusText);
        }

        const data = await response.json();
        const result: ExperianPhoneResult = data.response.result;
        if (experianPhoneAccepted.indexOf(result.confidence) !== -1) {
          setPhoneNumber(result.formatted_phone_number);
          setPhoneMessage('');
          setPhoneNumberValidated(true);
          inputSuccess(event, id);
        } else {
          setPhoneNumberValidated(false);
          setPhoneMessage('Invalid phone number');
          inputError(event, id);
        }
      } catch (error) {
        console.log('Error', error);
        setPhoneMessage('Unable to validate phone number');
        setPhoneNumberValidated(false);
      }
      setPhoneSearching(false);
    } else {
      setPhoneMessage('Please enter a contact phone number');
    }
  }

  /**
   * Validate form and provide error messages if data is not correct.
   */
  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    let error = false;
    let errorElement = 'content';

    if (donation?.relationship?.length && ! (PRESET_RELATIONS).includes(donation.relationship)) {
      setShowOtherRelation(true);
      setRelationship(Relationship.OTHER);
      setOtherRelation(donation.relationship);
    }

    if (!relationship.length) {
      error = true;
      errorElement = 'relationship';
      setRelationMessage('Please enter your relationship to UQ');
    } else if (relationship === Relationship.OTHER && !otherRelation.length) {
      error = true;
      errorElement = 'other';
      setOtherRelationMessage('Please specify');
    }

    if (!emailValidated) {
      await onEmailChanged(undefined, 'email');
    }

    if (!phoneNumberValidated) {
      await onPhoneChanged(undefined, 'phoneNumber');
    }

    if (!surname.length) {
      error = true;
      errorElement = 'surname';
      setSurnameMessage('Please enter your surname');
    }

    if (!givenName.length) {
      error = true;
      errorElement = 'givenName';
      setGivenNameMessage('Please enter your given name');
    }

    /*
      Only TITLES available in the list of TITLES should be considered 'valid' TITLES.
      All other values should be considered 'invalid'
     */
    if (titleOptions && ! Object.values(titleOptions).includes(title) && title !== '') {
      setTitleMessage('Invalid title');
      error = true;
      errorElement = 'title';
    } else {
      setTitleMessage('');
    }

    if (showOrganisationFields && ! organisationName.length) {
      setOrganisationNameMessage('Please enter an organisation name');
      error = true;
      errorElement = 'content';
    } else {
      setOrganisationNameMessage('');
    }

    if (error) {
      document.getElementById(errorElement)?.scrollIntoView({
        behavior: 'smooth'
      });
      return;
    }

    if (
      emailValidated &&
      phoneNumberValidated &&
      ((showOrganisationFields && organisationName.length) || !showOrganisationFields)
    ) {
      // Create a temp object for merging our properties
      const donationObject: any = {};
      donationObject.title = title;
      donationObject.firstName = givenName;
      donationObject.lastName = surname;
      donationObject.email = email;
      donationObject.phonePrefix = phonePrefix;
      donationObject.phone = phoneNumber;
      donationObject.emailValidated = emailValidated;
      donationObject.phoneValidated = phoneNumberValidated;
      donationObject.relationship = relationship;

      if (showOrganisationFields) {
        donationObject.companyName = organisationName ?? '';
        donationObject.companyABN = organisationABN ?? '';
        donationObject.companyPosition = organisationPositionTitle ?? '';
      } else {
        donationObject.companyName = '';
        donationObject.companyABN = '';
        donationObject.companyPosition = '';
      }

      if (relationship === Relationship.OTHER) {
        donationObject.relationship = otherRelation;
      }

      // Update the donor with the original information merged with the updated properties
      setDonation({...donation, ...donationObject});
      metrics.pushData({
        ...linkData,
        click_action: 'Step click',
        click_label: 'Next',
        click_url: `${window.location.origin}/${fundUrl}/${donationAmount}/address`
      });
      history.push(`/${fundUrl}/${donationAmount}/address`);
    }
  }

  // @ts-ignore - Group up the countries dialing codes to remove duplicates
  const phoneOptions = [...new Map(countries.map(item => [item['ext'], item])).values()];
  // Remove our top 3 - Australia, USA, UK
  const aus = phoneOptions.shift();
  const usa = phoneOptions.shift();
  const uk = phoneOptions.shift();
  // Sort the remainder
  phoneOptions.sort((a, b) => a['ext'] - b['ext']);
  // Prepend our top 3 again.
  phoneOptions.unshift(uk as CountryCodes);
  phoneOptions.unshift(usa as CountryCodes);
  phoneOptions.unshift(aus as CountryCodes);

  return (
    <div className="uq-grid uq-grid--golden">
      <div className="uq-grid__col">
        <span className="fund-steps">Step 2 of 4</span>
        <h1 className="gift-heading">My gift to {fund.friendlyName} of ${donationAmount}
          { donation.recurring ? ` every ${periodToString(donation.recurringPeriod)}` : '' }
        </h1>
        <h2 className="step-heading">Personal details</h2>
        <form className="donation-form" onSubmit={event => handleSubmit(event)}>

          <fieldset>
            <legend style={{display: "none"}}>Organisation information</legend>
            <input
              type="checkbox"
              id="organisation"
              name="organisation"
              aria-describedby="organisationFeedback"
              checked={showOrganisationFields}
              value='true'
              onChange={onShowOrganisationChange}
            />
            <label htmlFor="organisation">Organisation</label>

            {showOrganisationFields ? <>
              <label htmlFor="organisationName">Organisation Name</label>
              <input
                className="uq-input--large"
                type="text"
                id="organisationName"
                name="organisationName"
                aria-describedby="organisationNameFeedback"
                value={organisationName}
                onChange={(event) => (showOrganisationFields ? setOrganisationName(event.target.value) : "")}
                onBlur={() => setOrganisationNameMessage((!organisationName ? 'Please enter an organisation name' : '')) }
                onFocus={() => {
                  metrics.pushData({
                    ...linkData,
                    click_action: 'Organisation Name',
                  });
                }}
              />

              {organisationNameMessage && organisationNameMessage.length ?
                <span id="organisationNameFeedback" aria-live="polite" className="uq-error-message">
                  {organisationNameMessage}
                </span> :
                ''
              }

              <label htmlFor="organisationABN">Organisation ABN:</label>

              <input
                className="uq-input--large"
                type="text"
                id="organisationABN"
                name="organisationABN"
                aria-describedby="organisationABNFeedback"
                value={organisationABN}
                onChange={(event) => (showOrganisationFields ? setOrganisationABN(event.target.value) : "")}
                onFocus={() => {
                  metrics.pushData({
                    ...linkData,
                    click_action: 'Organisation ABN',
                  });
                }}
              />


              <label htmlFor="organisationPositionTitle">Organisation Position Title:</label>

              <input
                className="uq-input--large"
                type="text"
                id="organisationPositionTitle"
                name="organisationPositionTitle"
                aria-describedby="organisationTitleFeedback"
                value={organisationPositionTitle}
                onChange={(event) => (showOrganisationFields ? setOrganisationPositionTitle(event.target.value) : "")}
                onFocus={() => {
                  metrics.pushData({
                    ...linkData,
                    click_action: 'Organisation Position Title',
                  });
                }}
              />
            </> :
            ''
          }
          </fieldset>

          <div className="uq-grid uq-grid--halves">
            <div className="uq-grid__col">
              <label htmlFor="title">Title:</label>

              <select
                name="title"
                id="title"
                multiple={false}
                value={title}
                onChange={(event) => setTitle(event.target.value)}
                aria-label="Title"
                onFocus={() => {
                  metrics.pushData({
                    ...linkData,
                    click_action: 'Title selection',
                  });
                }}
              >
                <option value="">Select a title</option>
                { Object.entries(titleOptions).map(([key, value]) => <option key={key} value={String(value)}>{String(value)}</option>)}
              </select>

              {titleMessage && titleMessage.length ?
                <span className="uq-error-message" id="titleFeedback" aria-live="polite">
                  {titleMessage}
                </span> :
                ''
              }
            </div>
          </div>

          <div>
            <label htmlFor="givenName">Given Name:</label>

            <input
              className="uq-input--large"
              type="text"
              id="givenName"
              name="givenName"
              value={givenName}
              aria-describedby="givenNameFeedback"
              onChange={(event) => setGivenName(event.target.value)}
              onBlur={(event) => {
                if (givenName) {
                  inputSuccess(event);
                } else {
                  inputError(event);
                }
                setGivenNameMessage((!givenName ? 'Please enter a given name' : ''));
              }}
              onFocus={() => {
                metrics.pushData({
                ...linkData,
                click_action: 'Given Name',
                });
              }}
            />

            {givenNameMessage && givenNameMessage.length ?
              <span className="uq-error-message" id="givenNameFeedback" aria-live="polite">
                {givenNameMessage}
              </span> :
              ''
            }
          </div>

          <div>
            <label htmlFor="surname">Surname:</label>

            <input
              className="uq-input--large"
              type="text"
              id="surname"
              name="surname"
              value={surname}
              aria-describedby="lastNameFeedback"
              onChange={(event) => setSurname(event.target.value)}
              onBlur={(event) => {
                if (surname) {
                  inputSuccess(event);
                } else {
                  inputError(event);
                }
                setSurnameMessage((!surname ? 'Please enter a surname' : ''))
              }}
              onFocus={() => {
                metrics.pushData({
                  ...linkData,
                  click_action: 'Last Name',
                });
              }}
            />

            {surnameMessage && surnameMessage.length ?
              <span className="uq-error-message" id="lastNameFeedback" aria-live="polite">
                {surnameMessage}
              </span> :
              ''
            }
          </div>

          <div className="email-search">
            <label htmlFor="email">Email:</label>
            <input
              className={emailValidated ? successClass : 'uq-input--large'}
              type="text"
              id="email"
              name="email"
              value={email}
              aria-describedby="emailFeedback"
              onChange={(event) => setEmail(event.target.value)}
              onBlur={(event) => {onEmailChanged(event)}}
              onFocus={() => {
                metrics.pushData({
                  ...linkData,
                  click_action: 'Email',
                });
              }}
            />
            {!emailSearching && !!emailMessage.length &&
              <span
                id="emailFeedback"
                className={emailValidated ? '' : errorClass}
                aria-live="polite"
              >
                {emailMessage}
              </span>
            }
            {emailSearching &&
            <div className="uq-loading-spinner email-search__spinner" role="alert" aria-live="assertive">
              <span>Searching...</span>
            </div>
            }

          </div>

          <div className="phone-search">
            <label htmlFor="phoneNumber">Phone:</label>

            <div className="grid-contact-number">

              <select
                name="phonePrefix"
                id="phonePrefix"
                multiple={false}
                value={phonePrefix}
                onChange={(event) => setPhonePrefix(event.target.value)}
                aria-label="Country phone prefix"
                onFocus={() => {
                  metrics.pushData({
                    ...linkData,
                    click_action: 'Phone Prefix',
                  });
                }}
              >
                {phoneOptions.map(o => <option key={o.ext} value={o.ext}>{o.ext}</option>)}
              </select>

              <input
                className={phoneNumberValidated ? successClass : 'uq-input--large'}
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                value={phoneNumber}
                aria-describedby="phoneNumberFeedback"
                onChange={event => setPhoneNumber(event.target.value)}
                onBlur={(event) => onPhoneChanged(event)}
                onFocus={() => {
                  metrics.pushData({
                    ...linkData,
                    click_action: 'Phone',
                  });
                }}
              />
            </div>
            {!phoneSearching && !!phoneMessage.length &&
              <span
                id="phoneNumberFeedback"
                className={phoneNumberValidated ? '' : errorClass}
                aria-live="polite"
              >
                {phoneMessage}
              </span>
            }
            {phoneSearching &&
            <div className="uq-loading-spinner phone-search__spinner" role="alert" aria-live="assertive">
              <span>Searching...</span>
            </div>
            }

          </div>

          <div>
            <label htmlFor="relationship">Your Primary Relationship to UQ:</label>

            <select
              name="relationship"
              id="relationship"
              multiple={false}
              value={relationship}
              onChange={(event) => setRelationship(event.target.value)}
              aria-label="Relationship to UQ"
              aria-describedby="relationFeedback"
              onFocus={() => {
                metrics.pushData({
                  ...linkData,
                  click_action: 'Relationship to UQ selection',
                });
              }}
              onBlur={(event) => {
                setRelationMessage((!relationship ? 'Please enter your relationship to UQ' : ''));
              }}
            >
              <option value="">Select a primary relationship</option>
              { Object.values(Relationship).map((value) =>
                <option key={value} value={value}>{relationToString(value)}</option>
              )}
            </select>

            {relationMessage && relationMessage.length ?
              <span className="uq-error-message" id="relationFeedback" aria-live="polite">
                  {relationMessage}
                </span> :
              ''
            }
          </div>

          { relationship === Relationship.STAFF &&
            <div>
              <p>
                Did you know that you can make regular donations through payroll and
                receive the tax benefit immediately?<br/>
                For more information visit&nbsp;
                <a href="https://alumni.uq.edu.au/give-uq/staff-giving-0" target="_blank" rel="noreferrer">
                  staff giving
                </a>.
              </p>
            </div>
          }

          { relationship === Relationship.OTHER &&
            <div>
              <label htmlFor="other">Please specify:</label>

              <input
                className="uq-input--large"
                type="text"
                id="other"
                name="other"
                value={otherRelation}
                aria-describedby="otherRelationFeedback"
                onChange={(event) => setOtherRelation(event.target.value)}
                onBlur={(event) => {
                  if (otherRelation) {
                    inputSuccess(event);
                  } else {
                    inputError(event);
                  }
                  setOtherRelationMessage((!otherRelation ? 'Please specify' : ''))
                }}
                onFocus={() => {
                  metrics.pushData({
                    ...linkData,
                    click_action: 'Other UQ Relation',
                  });
                }}
              />

              {otherRelationMessage && otherRelationMessage.length ?
                <span className="uq-error-message" id="otherRelationFeedback" aria-live="polite">
                  {otherRelationMessage}
                </span> :
                ''
              }
            </div>
          }

          <div className="grid-buttons">
            <button type="submit" className="uq-button">Next</button>
            <Link to={`/${fundUrl}`} className="uq-button uq-button--text" onClick={() => {
              metrics.pushData({
                ...linkData,
                click_action: 'Step click',
                click_label: 'Back',
                click_url: `${window.location.origin}/${fundUrl}`
              });
            }}>Back</Link>
          </div>

          <Alert
            container={true}
            level={AlertLevel.INFO}
            message={
              <>
                <p>If you experience any issues processing your donation, please call UQ Advancement and Community
                  Engagement team on<a href="tel:+61733463909" style={{marginLeft: '5px'}}>07 3346 3909</a> and one
                  of our team members will be happy to assist.</p>
                <p>Thank you for your support.</p>
              </>
            }
            display={true}
          />
        </form>
      </div>
    </div>
  )
}
