import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as API from '../../API';
import { Donation, periodToString } from '../../classes/Donation';
import { Fund } from '../../classes/Fund';
import { Metrics } from '../../classes/Metrics';
import { inputError, inputSuccess } from '../../Helpers';
import Alert, { AlertLevel } from '../Alert/Alert';
import CardNumberInput from '../Payments/CardNumberInput';
import ProcessPayment from '../ProcessPayment/ProcessPayment';

/* eslint-disable react/jsx-no-target-blank */

export default function ConfirmDonation(
  { fund, donation, setDonation, appealId, metrics }:
  {
    fund: Fund,
    donation: Donation,
    setDonation: React.Dispatch<React.SetStateAction<Donation>>,
    appealId: string,
    metrics: Metrics,
  }
) {
  const isProduction = process.env.REACT_APP_SITE_DOMAIN === 'donations.uq.edu.au';
  const [bequest, setBequest] = useState<boolean>(false);
  const [anonymous, setAnonymous] = useState<boolean>(false);
  const [includedInWill, setIncludedInWill] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [yearOptions, setYearOptions] = useState<Array<number>>([]);
  // Card Data
  const [cardholderName, setCardholderName] = useState<string>('');
  const [cardholderNameMessage, setCardholderNameMessage] = useState<string>('');
  const [cardNumber, setCardNumber] = useState<string>('');
  const [cardValid, setCardValid] = useState<boolean>(true);
  const [cardExpMonth, setCardExpMonth] = useState<string>('');
  const [cardExpMonthMessage, setCardExpMonthMessage] = useState<string>('');
  const [cardExpYear, setCardExpYear] = useState<string>('');
  const [cardExpYearMessage, setCardExpYearMessage] = useState<string>('');
  const [cardCVC, setCardCVC] = useState<string>('');
  const [CVCLength, setCVCLength] = useState<number>(3);
  const [cardCVCMessage, setCardCVCMessage] = useState<string>('');
  const [processingMessage, setProcessingMessage] = useState<string>('Establishing a secure connection');
  const [captchaMessage, setCaptchaMessage] = useState<string>('');
  const [alertLevel, setAlertLevel] = useState<AlertLevel>(AlertLevel.INFO);
  const [alertMessage, setAlertMessage] = useState<string>('Loading...');
  const [alertDisplay, setAlertDisplay] = useState<boolean>(false);
  const [checkValidity, setCheckValidity] = useState<number>(0);
  const [debugValue, setDebugValue] = useState<string|undefined>(undefined);
  const history = useHistory();
  const linkData = useMemo(() => {
    return {
      event: 'Custom click'
    };
  }, []);

  const storeAndRedirect = useCallback(async (donationObj: Donation) => {
    setAlertDisplay(false);
    // @ts-ignore
    const captcha = grecaptcha.enterprise.getResponse();

    const donationResponse = await API.addDonation({
      ...donationObj,
      debugValue: debugValue,
      captcha: captcha,
    });
    if (donationResponse.status === 401) {
      // @ts-ignore
      grecaptcha.enterprise.reset();
      setLoading(false);
      setAlertLevel(AlertLevel.ERROR);
      setAlertMessage('An error has occurred. Please retry verification challenge.');
      setAlertDisplay(true);
    }

    if (donationResponse.status === 200) {
      const donationJson = await donationResponse.json();

      if (donationJson.errors !== undefined) {
        setLoading(false);
        setAlertLevel(AlertLevel.ERROR);
        setAlertMessage('An error has occurred. No payment information has been processed.');
        setAlertDisplay(true);
      }

      // Update our local object with the stored data.
      donationObj.identifier = donationJson.identifier;
      donationObj.invoiceNumber = donationJson.invoiceNumber || '';
      setDonation(donationObj);

      const paymentUrl = donationJson.paymentUrl;

      const params = {
        ewayjsonp: donationJson.code,
        EWAY_ACCESSCODE: donationJson.accessCode,
        EWAY_PAYMENTTYPE: 'Credit Card',
        EWAY_CARDNAME: cardholderName.substring(0, 50), // eWay limit 50 characters
        EWAY_CARDNUMBER: cardNumber.replace(/\s/g, ''),
        EWAY_CARDEXPIRYMONTH: cardExpMonth,
        EWAY_CARDEXPIRYYEAR: cardExpYear,
        EWAY_CARDCVN: cardCVC,
      };

      const queryParams = new URLSearchParams(params);

      // Fire off the request, we do not care about it beyond this
      await fetch(`${paymentUrl}?${queryParams.toString()}&`, {
        mode: 'no-cors'
      });

      setProcessingMessage('Processing your donation');
      const paymentResponse = await API.lookupTransaction(
        'donations',
        donationJson.accessCode,
        'eway',
        isProduction ? 'prod' : 'sandbox'
      );

      if (paymentResponse.success) {
        /* https://c.tenor.com/XiaW-J5uxP0AAAAC/dracula-van-helsing.gif */
        // Set the local Donation object to have the date.
        setDonation({ ...donationObj, responseAt: paymentResponse.timestamp });

        // Set the item to local storage so if the user refreshes the success page, we can still show something
        localStorage.setItem('donation', JSON.stringify(donationObj));
        history.push(`/success`);
      } else {
        // @ts-ignore
        grecaptcha.enterprise.reset();
        console.error(`Error: ${paymentResponse.error}`);
        setLoading(false);
        setAlertLevel(AlertLevel.ERROR);
        setAlertMessage(
          paymentResponse.error ?? 'An error has occurred. Please do not retry, as payment may have succeeded'
        );
        setAlertDisplay(true);
      }
    } else {
      // @ts-ignore
      grecaptcha.enterprise.reset();
      console.error('Unable to save your donation information.');
      setLoading(false);
      setAlertLevel(AlertLevel.ERROR);
      setAlertMessage('Unable to save your donation information.');
      setAlertDisplay(true);
    }
    // @ts-ignore
    grecaptcha.enterprise.reset();
  }, [
    history,
    setDonation,
    cardNumber,
    cardholderName,
    cardExpMonth,
    cardExpYear,
    cardCVC,
    debugValue,
    isProduction
  ]);

  const handleSubmit = useCallback(async () => {
    // TODO - Validate fields
    let error = false;
    if (cardholderName.length <= 3) {
      error = true;
      setCardholderNameMessage('Please enter the name on the card');
    }

    // Update the checkValidity state to trigger a check
    setCheckValidity(checkValidity + 1);
    if (!cardValid) {
      error = true;
    }

    if (Number(cardExpMonth) < 1 || Number(cardExpMonth) > 12) {
      error = true;
      setCardExpMonthMessage('Invalid Month');
    } else {
      setCardExpMonthMessage('');
    }

    if (yearOptions.indexOf(Number(cardExpYear)) === -1) {
      error = true;
      setCardExpYearMessage('Invalid Year');
    } else {
      setCardExpYearMessage('');
    }

    if (cardCVC.length !== CVCLength) {
      error = true;
      setCardCVCMessage('Enter a valid CVC');
    } else {
      setCardCVCMessage('');
    }

    // @ts-ignore
    if (grecaptcha.enterprise.getResponse() === '') {
      error = true;
      setCaptchaMessage('Please complete the challenge');
    } else {
      setCaptchaMessage('');
    }

    if (error) {
      return;
    }

    setLoading(true);
    metrics.pushData({
      ...linkData,
      event: 'Custom click',
      click_action: 'Step click',
      click_label: 'Submit',
      click_url: '/process'
    });

    try {
      let templateName = fund.receiptTemplate;
      if (donation.recurring) {
        templateName = fund.acknowledgementTemplate;
      }
      const fundName = fund.friendlyName ?? fund.shortName;

      if (!donation.identifier || donation.identifier === '') {
        const donationObj = {
          ...donation,
          bequestContact: bequest,
          appealId: appealId,
          anonymous: anonymous,
          chartString: fund.chartString,
          fundName: fundName,
          fundId: fund.id,
          templateName: templateName,
          includedInWill: includedInWill
        };
        setDonation(donationObj);
        await storeAndRedirect(donationObj);
      } else {
        // Append chart string to donation
        const donationObj = {
          ...donation,
          bequestContact: bequest,
          anonymous: anonymous,
          chartString: fund.chartString,
          fundName: fundName,
          fundId: fund.id,
          templateName: templateName,
          includedInWill: includedInWill
        };
        await storeAndRedirect(donationObj);
      }
      // TODO - Error message here if user was not redirected.
    } catch (e) {
      // @ts-ignore
      grecaptcha.enterprise.reset();
      setLoading(false);
      console.error(e);
    }
  },
  [
    donation,
    fund,
    includedInWill,
    bequest,
    appealId,
    anonymous,
    setDonation,
    storeAndRedirect,
    CVCLength,
    cardCVC,
    cardExpMonth,
    cardExpYear,
    cardValid,
    cardholderName,
    checkValidity,
    linkData,
    metrics,
    yearOptions,
  ]);

  useEffect(() => {
    // Redirect if information is missing
    if (fund.id === '') {
      history.push('/');
    } else if (donation.amount < 5) {
      history.push(`/${fund.id}`);
    } else if (donation.firstName === '') {
      history.push(`/${fund.id}/${donation.amount}`);
    } else if (donation.addressLine1 === '') {
      history.push(`/${fund.id}/${donation.amount}/address`);
    }

    document.getElementById('content')?.scrollIntoView({
      behavior: 'smooth'
    });

    // @ts-ignore - Initialise the accordion on this page
    new uq.accordion();
    // Prepare year selector options for credit cards
    let year = new Date().getFullYear() % 100;
    const years = [];

    for (let endYear = year + 10; year < endYear; year++) {
      years.push(year);
    }
    setYearOptions(years);

    // Init the captcha
    setTimeout(() => {
      const elm = document.getElementById('captcha');

      if (elm?.hasChildNodes()) {
        // @ts-ignore
        grecaptcha.enterprise.reset('captcha');
      } else {
        // @ts-ignore
        grecaptcha.enterprise.render('captcha', {
          sitekey: process.env.REACT_APP_GOOGLE_API_SITE_KEY
        });
      }
    }, 1000);

  }, [fund, donation, history]);

  return (<>
    <ProcessPayment
      processing={loading}
      processingMessage={processingMessage}
    />
    <div className="uq-grid uq-grid--golden">
      <div className="uq-grid__col">
        <span className="fund-steps">Step 4 of 4</span>
        <h1 className="gift-heading">My gift to {fund.friendlyName} of ${donation.amount}
          { donation.recurring ? ` every ${periodToString(donation.recurringPeriod)}` : '' }
        </h1>
        <h2 className="step-heading">Confirm donation details</h2>

        <Alert
          level={alertLevel}
          message={alertMessage}
          spinner={false}
          spinnerMessage={alertMessage}
          display={alertDisplay}
        />

        <form className="donation-form" id="paymentForm" onSubmit={(event) => event.preventDefault()}>
          <ul className="list-confirmation">
            <li><strong>Fund:</strong> {fund.friendlyName}</li>
            <li><strong>Amount:</strong> ${donation.amount}</li>
            <li><strong>Payment type:</strong> {!donation.recurring ? 'One off donation' : `Recurring donation (${donation.recurringPeriod})`}</li>
          </ul>

          <div>
            <label htmlFor="cardholderName">Name on card:</label>

            <input
              className="uq-input--large"
              type="text"
              id="cardholderName"
              name="cardholderName"
              value={cardholderName}
              aria-describedby="cardholderNameFeedback"
              onChange={(event) => setCardholderName(event.target.value)}
              onBlur={(event) => {
                if (cardholderName.length > 3) {
                  setCardholderNameMessage('');
                  inputSuccess(event);
                } else {
                  setCardholderNameMessage('Please enter the name on the card');
                  inputError(event);
                }
              }}
              onFocus={() => {
                metrics.pushData({
                  ...linkData,
                  click_action: 'Cardholder Name',
                });
              }}
            />

            {cardholderNameMessage && cardholderNameMessage.length ?
              <span className="uq-error-message" id="cardholderNameFeedback" aria-live="polite">
                {cardholderNameMessage}
              </span> :
              ''
            }
          </div>
          <div>
            <CardNumberInput
              label="Card Number"
              name="cardNumber"
              cardNumber={cardNumber}
              setCardNumber={setCardNumber}
              metrics={metrics}
              cardNumberErrorMessage="Please enter a valid Credit Card number"
              checkValidity={checkValidity}
              cardValid={cardValid}
              setCardValid={setCardValid}
              setCVCLength={setCVCLength}
            />
          </div>

          <div className="uq-grid">
            <div className="uq-grid__col uq-grid__col--3 uq-grid__col--md-4 uq-grid__col--xl-3">
              <label htmlFor="cardExpMonth">Expiry month:</label>
              <select
                className="uq-input--large"
                id="cardExpMonth"
                name="cardExpMonth"
                aria-describedby="cardExpMonthFeedback"
                multiple={false}
                value={cardExpMonth}
                onChange={(event) => setCardExpMonth(event.target.value)}
                onFocus={() => {
                  metrics.pushData({
                    ...linkData,
                    click_action: 'Credit Card Expiry Month selection',
                  });
                }}
              >
                <option value="" disabled={true}>--</option>
                <option value="01">01</option>
                <option value="02">02</option>
                <option value="03">03</option>
                <option value="04">04</option>
                <option value="05">05</option>
                <option value="06">06</option>
                <option value="07">07</option>
                <option value="08">08</option>
                <option value="09">09</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>

              {cardExpMonthMessage && cardExpMonthMessage.length ?
                <span className="uq-error-message" id="cardExpMonthFeedback" aria-live="polite">
                {cardExpMonthMessage}
              </span> :
                ''
              }
            </div>

            <div className="uq-grid__col uq-grid__col--3 uq-grid__col--md-4 uq-grid__col--xl-3">
              <label htmlFor="cardExpYear">Expiry year:</label>
              <select
                className="uq-input--large"
                name="cardExpYear"
                id="cardExpYear"
                aria-describedby="cardExpYearFeedback"
                multiple={false}
                value={cardExpYear}
                onChange={(event) => setCardExpYear(event.target.value)}
                onFocus={() => {
                  metrics.pushData({
                    ...linkData,
                    click_action: 'Credit Card Expiry Year selection',
                  });
                }}
              >
                <option value="" disabled={true}>--</option>
                { yearOptions.map(year => {
                  return <option value={year.toString()} key={year}>{year}</option>
                })}
              </select>

              {cardExpYearMessage && cardExpYearMessage.length ?
                <span className="uq-error-message" id="cardExpYearFeedback" aria-live="polite">
                {cardExpYearMessage}
              </span> :
                ''
              }
            </div>

            <div className="uq-grid__col uq-grid__col--6 uq-grid__col--md-8 uq-grid__col--xl-6">
              <label htmlFor="cardCVC">CVC:</label>
              <input
                className="uq-input--large"
                type="text"
                name="cardCVC"
                id="cardCVC"
                value={cardCVC}
                onChange={(event) => setCardCVC(event.target.value)}
                onFocus={() => {
                  metrics.pushData({
                    ...linkData,
                    click_action: 'Credit Card CVC selection',
                  });
                }}
              />

              {cardCVCMessage && cardCVCMessage.length ?
                <span className="uq-error-message" id="cardExpYearFeedback" aria-live="polite">
                {cardCVCMessage}
              </span> :
                ''
              }
            </div>
          </div>

          <div>
            <input id="will_included" type="checkbox" name="will_included" onChange={(event) => {
              setIncludedInWill(event.target.checked);
              metrics.pushData({
                ...linkData,
                event: 'Custom click',
                click_action: 'Will gift',
                click_label: 'I have included a gift to UQ in my Will',
                click_url: undefined
              });
            }}/>
            <label htmlFor="will_included">I have included a gift to UQ in my Will</label>
          </div>
          <div>
            <input id="bequest" type="checkbox" name="confirm" onChange={(event) => {
              setBequest(event.target.checked);
              metrics.pushData({
                ...linkData,
                event: 'Custom click',
                click_action: 'Bequests',
                click_label: 'Please send me information about Gifts in Wills',
                click_url: undefined
              });
            }}/>
            <label htmlFor="bequest">Please send me information about <strong>Gifts in Wills</strong></label>
          </div>
          <div>
            <input id="public" type="checkbox" name="public" onChange={(event) => {
              setAnonymous(event.target.checked);
              metrics.pushData({
                ...linkData,
                event: 'Custom click',
                click_action: 'Anonymous gift',
                click_label: 'I would like this gift to remain anonymous',
                click_url: undefined
              });
            }}/>
            <label htmlFor="public">I would like this gift to remain anonymous</label>
          </div>

          <div>
            <div id="captcha"></div>
            {captchaMessage && captchaMessage.length ?
              <span className="uq-error-message" id="captchaFeedback" aria-live="polite">
                {captchaMessage}
              </span> :
              ''
            }
          </div>

          <div className="grid-buttons">
            <button className="uq-button" onClick={handleSubmit}>Submit</button>
            <Link className="uq-button uq-button--text"
              to={`/${fund.url || fund.id}/${donation.amount}/address`}
              style={loading ? {pointerEvents: 'none', color: '#8EB8E2'} : undefined }
              onClick={() => {
                metrics.pushData({
                  ...linkData,
                  click_action: 'Step click',
                  click_label: 'Back',
                  click_url: `${window.location.origin}/${fund.url || fund.id}/${donation.amount}/address`
                });
              }}
            >Back</Link>
          </div>

          <Alert
            container={true}
            level={AlertLevel.INFO}
            message={
              <>
                <p>If you experience any issues processing your donation, please call UQ Advancement and Community
                  Engagement team on<a href="tel:+61733463909" style={{marginLeft: '5px'}}>07 3346 3909</a> and one
                  of our team members will be happy to assist.</p>
                <p>Thank you for your support.</p>
              </>
            }
            display={true}
          />

        </form>

        <div className="uq-accordion fund-accordion" aria-label="Accordion button group">
          <div className="uq-accordion__item">
            <button className="uq-accordion__toggle" aria-controls="content-1111" aria-expanded="false"
              id="accordion-title-1111"
              onClick={(event: any) => {
                metrics.pushData({
                  ...linkData,
                  event: 'Accordion',
                  click_action: event.target.ariaExpanded === 'false',
                  click_label: 'Privacy,terms and conditions',
                  click_url: undefined
                });
              }}
            >Privacy, terms and conditions
            </button>
            <div className="uq-accordion__content" role="region" aria-hidden="true" id="content-1111"
                 aria-labelledby="accordion-title-1111">
              <p>The University of Queensland (UQ) collects the information you provided on this webpage for the
                purpose of collecting and administering your gift. UQ will also use this information to keep in touch
                with you regarding the impact of your donation, appeals, events and other engagement opportunities.
              </p>

              <p>
                Where your gift is in support of an external UQ <a
                href="https://alumni.uq.edu.au/donation-form-collection-notice-UQ-affiliate"
                target="_blank">affiliate</a> UQ will disclose the information you provide to that affiliate for
                the above purposes.
              </p>

              <p>
                The University of Queensland will disclose the information you provide to eWay for payment purposes.
                UQ will not otherwise disclose this information to a third party without your agreement, except in
                accordance with the <cite>Information Privacy Act 2009</cite> and other relevant privacy laws. For
                further information, please refer to the University's <a
                href="https://policies.uq.edu.au/document/view-current.php?id=4"
                target="_blank">Privacy Management Policy
                </a>.
              </p>

              <p>Your gift will be used to support the University's teaching and research endeavours. Upon donating to
                UQ, you acknowledge that your donation is a gift to UQ and is not intended to create a trust. For any
                enquiries please call UQ Advancement and Community Engagement team on
                <a href="tel:+61733463909" style={{marginLeft: '5px'}}>07 3346 3909
                </a>.
              </p>

              <p>Donations of $2 or more may be claimed as tax deductions. To find out more about the tax benefits for
                your gift please visit <a href="https://giving.uq.edu.au/details-how-to-give"
                                          target="_blank">giving.uq.edu.au/details-how-to-give</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
}
